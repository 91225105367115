<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content p-3">
      <div v-if="loading">
        <ChLoading size="sm" class="spinner" />
      </div>
      <div v-else class="d-flex align-items-center justify-content-center">
        <img class="cheer-img" src="/giftGems.png" />
        <div class="d-flex align-items-center flex-column">
          <div class="cheer-title">YOU GOT GEMS!</div>
          <div class="d-flex align-items-center">
            <router-link
              :to="{ name: 'profile', params: { username: get(user, 'username') } }"
              class="d-flex align-items-center justify-content-center text-center mr-1"
            >
              @{{ get(user, 'username') }}</router-link
            >
            sent you gems!
          </div>
        </div>
      </div>
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button
            color="transparent"
            class="close-btn"
            @click="dismissModal"
            :disabled="get(route.query, 'response')"
          >
            Say Thanks</ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  user: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const emits = defineEmits(['close', 'thanks']);
const isOpen = toRef(props, 'isOpen');
const dismissModal = () => {
  isEmpty(get(route.query, 'response')) ? emits('thanks') : emits('close');
};
</script>
<style lang="sass" scoped>
.cheer-title
  font: bolder 28px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #A04BFE
.cheer-img
  object-fit: cover

.dark .toolbar
  --background: #17074c !important
.close-btn
  border-radius: 12px
  text-transform: none
  --background: #7050B7
  background:  #7050B7
  color: white
  height: 30px
  font-weight: 600
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 70vh
  overflow: auto
  max-height: 70dvh

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px

.title
  color: #88DC3B
  font-size: 20px
.dark .title
  color: #88DC3B !important
</style>
