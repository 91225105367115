<template>
  <div
    class="p-2 d-flex align-items-center position-relative mt-4"
    :class="freebieStatus ? 'claimed-box' : 'claim-box'"
    style="background-repeat: no-repeat !important; background-size: cover !important; color: white"
    :style="freebieGiftSvgBg"
  >
    <div class="timer-badge">
      <ion-badge v-if="freebieStatus" style="background: #214163">
        <vue-countdown v-slot="{ hours, minutes, seconds }" :time="freebieClaimTimer">
          <span class="countdown">
            <span v-if="hours">{{ hours }}h </span><span v-if="minutes">{{ minutes }}m </span
            ><span v-if="!hours && !minutes">{{ seconds }}s</span>
          </span>
        </vue-countdown>
      </ion-badge>
      <ion-badge v-else class="badge no-select">FREE</ion-badge>
    </div>
    <div class="d-flex align-items-center justify-content-center" :class="freebieStatus ? 'mr-2' : 'box-img'">
      <inline-svg v-if="freebieStatus" class="open-gift-box" src="/open_gift_box.svg" />
      <inline-svg v-else class="gift-box" src="/gift_box.svg" />
    </div>

    <div v-if="freebieStatus" class="py-3 d-flex justify-content-between align-items-center free-claim-box w-100">
      <ClaimedBoxes :claimedBoxes="claimedBoxes" />
      <div></div>
      <div class="d-flex align-items-center justify-content-center text-center other-sites px-1 flex-column">
        Find the others all around the site!
        <currencyHint :claimedBoxes="claimedBoxes">
          <div class="hint clickable-item-hov">HINT</div>
        </currencyHint>
      </div>
    </div>

    <div v-else class="d-flex justify-content-between align-items-center">
      <div class="gift-box-text w-100 no-select">DAILY GIFT BOXES</div>

      <div class="claim">
        <currencyTransBox @fetch="fetchClaimedBoxes" :progressId="get(usersFreebie, 'id')">
          <ion-button class="claim-button no-select" @click="action">CLAIM</ion-button>
        </currencyTransBox>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import constants from '@/shared/statics/constants';
import { getUsersClaimedBoxes } from '@/shared/actions/currency';
import { authStore } from '@/shared/pinia-store/auth';
import ClaimedBoxes from './ClaimedBoxes.vue';
import currencyTransBox from '@/shared/components/currencyTransGiftBox.vue';
import CurrencyHint from '@/shared/pages/store/CurrencyHint.vue';
const { user, getUserFreebieDetails, getUsersCurrency, usersCurrency, usersFreebie } = authStore();
const claimedBoxes: any = ref({});
const freebieClaimTimer = computed(() => {
  const localTime = new Date();

  const currTimeUTC = new Date(localTime.toUTCString()) as any;

  const nextMidnightUTC = new Date(
    Date.UTC(currTimeUTC.getUTCFullYear(), currTimeUTC.getUTCMonth(), currTimeUTC.getUTCDate() + 1, 0, 0, 0, 0)
  ) as any;

  const timeDiff = nextMidnightUTC - currTimeUTC;
  return timeDiff;
});

const freebieStatus = computed(() => {
  return get(usersFreebie.value, 'status') === constants.freebieStatus.open;
});

const freebieGiftSvgBg = computed(() => {
  if (freebieStatus.value) {
    return ` background: linear-gradient(to right, rgb(222 23 214) 15%, rgb(212 29 217) 29%, rgb(191 44 223) 98%, rgb(169 51 217) 100%)`;
  } else {
    return `background-image: url(/daily_gift_card_bg.svg)`;
  }
});

const fetchUserClaimedBoxes = async () => {
  const resp = await getUsersClaimedBoxes();
  claimedBoxes.value = resp;
};

const action = async () => {
  getUserFreebieDetails();
  getUsersCurrency();
};

const fetchClaimedBoxes = () => {
  fetchUserClaimedBoxes();
  action();
};
onMounted(() => {
  fetchUserClaimedBoxes();
});
</script>

<style lang="sass" scoped>
.hint
  font-weight: bold
  font-size: 18px
  text-decoration: underline
  @media(max-width:505px)
    font-size: 16px

.gift-box
  width: 118px
  height: auto
  @media(max-width:500px)
    width: 124px
  @media(max-width:380px)
    width: 90px
.other-sites
  width:100%
  @media(max-width:555px) and (min-width: 440px)
    width: calc(100% - 107px)
  @media(max-width:440px) and (min-width: 397px)
    width: calc(100% - 131px)
  @media(max-width:397px) and (min-width:350px)
    font-size: 14px
    width:50%
  @media(max-width:350px)
    width:100%
    margin-top: 4px

.open-gift-box
  width: 105px
  aspect-ratio: 1
  height: auto
  @media(max-width:505px)
    width: 70px !important
  @media(max-width:380px)
    width: 50px !important
  @media(max-width:350px)
    width: 65px !important
.gift-box-text
  font: bolder 33px "Roboto Slab", sans-serif
  -webkit-text-fill-color: white
  text-align: center
  -webkit-text-stroke: 2px #421c88
  @media(max-width:575px) and (min-width:533px)
    padding-right: 0.5rem
    font: bolder 32px "Roboto Slab", sans-serif
    padding-left: 0.5rem
  @media(max-width:400px)
    font: bolder 29px "Roboto Slab", sans-serif
  @media(max-width:381px)
    font: bolder 24px "Roboto Slab", sans-serif
  // font-size:34px
  // color: white
  // --stroke-color: red
  // stroke-width: 5px
  // text-shadow: 2px 0 #214163, -2px 0 #214163, 0 2px #214163, 0 -2px #214163, 1px 1px #214163, -1px -1px #214163, -1px 1px #214163, 1px -1px #214163


.free-claim-box
  border-radius: 15px
  border: 2px solid white
  @media(min-width:599px)
    padding-right: 1rem
  @media(max-width:399px) and (min-width: 350px)
    padding-right: 0.5rem
    padding-left: 0.5rem
  @media(max-width:350px)
    flex-direction: column
.badge
  border: 2px solid #214163
  border-radius: 9px
  font-size: 12px
  background: linear-gradient(to right, rgb(93 214 59), rgb(33 147 102))
  padding: 3px 7px 3px 7px
.timer-badge
  position: absolute
  top: -10px
  left: -3px
.box-img
  position: relative
  left: 14px
  top: 8px
  //margin-right: 1rem
  @media(max-width:598px) and (min-width:575px)
    left: 8px
    top: 4px
  @media(max-width:574px) and (min-width:545px)
    top: 1px
    left: 6px
  @media(max-width:505px)
    margin-right: 0.5rem
    left:10px
    top:1px
.claim-box
  border-radius: 3px 18px 18px 18px
  border: 3px solid #214163
  background: #c225e8
.claimed-box
  border-radius: 3px 18px 18px 18px
  border: 3px solid #214163
  background: linear-gradient(to right, rgb(222 23 214) 15%, rgb(212 29 217) 29%, rgb(191 44 223) 98%, rgb(169 51 217) 100%)
.claim
    width: 100%
.claim-button
  font: 950 28px "Roboto Slab", sans-serif
  width: 100%
  --background: linear-gradient(90deg, rgba(6, 247, 233, 0.7) 0%, rgba(6, 131, 247, 0) 100%), #00AAE0
  -webkit-text-fill-color: white
  -webkit-text-stroke: 1px #214163
  border-radius: 17px
  border: 2px solid #214163
  --border-radius: 15px
  --padding-start: 10px
  --padding-end: 10px
  @media(max-width:410px)
   font: 950 22px "Roboto Slab", sans-serif
</style>
