<template>
  <div v-if="product" class="product-listing clickable-item-hov" @click="startPurchase">
    <div class="image-area position-relative d-flex align-items-center">
      <div v-if="product.discount_percent" class="tag-top-left position-absolute">
        <div class="tag d-flex no-select">{{ product.discount_percent }}% Off</div>
      </div>
      <div v-if="product.is_new" class="tag-top-right position-absolute">
        <div class="tag d-flex-center no-select">New</div>
      </div>
      <div class="product-image"><img class="no-select" :src="product.image" /></div>
    </div>

    <div class="center-area d-flex flex-column align-items-center justify-content-center ml-1">
      <div class="w-100">
        <h2 class="no-select product-name">{{ product.name }}</h2>
      </div>
      <div class="w-100">
        <p class="no-select product-description">{{ product.description }}</p>
      </div>
    </div>

    <div class="button-area mr-2">
      <ion-button class="price-button">
        <div class="button-inner" />
        <div class="position-relative">
          <inline-svg v-if="product?.amount_gems" src="/gem.svg" class="svg" />
        </div>
        <span class="price-text">{{ formatGems(product?.amount_gems) }}</span>
      </ion-button>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <i
          class="ti-info-alt icon py-2 px-4"
          v-if="product.description_html"
          @click.stop.prevent="openDescriptionModal"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { openBuyWithGemsModal } from '@/shared/utils/modals';
import { authStore } from '@/shared/pinia-store/auth';
import { StoreProduct } from '@/shared/types/static-types';
import { COMPLIMENT_BOMB } from '@/shared/statics/constants';
import { modalController } from '@ionic/vue';
import PurchaseSuccessModal from '@/shared/modals/PurchaseSuccessModal.vue';
import SiteHtmlModal from '@/shared/modals/SiteHtmlModal.vue';
import { openGemShopModal } from '@/shared/utils/modals';
const { getUsersCurrency } = authStore();

const props = defineProps({
  product: {
    type: Object as PropType<StoreProduct>,
  },
});
const emits = defineEmits(['onPurchase']);
const product = toRef(props, 'product');

const formatGems = (amount?: number) => {
  if (!amount) return;
  if (amount >= 1000000) {
    return (Math.floor(amount / 10000) / 100).toFixed(2) + 'M';
  } else {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

const openPurchaseSuccessModal = async () => {
  if (!product.value) return;
  const modal = await modalController.create({
    component: PurchaseSuccessModal,
    cssClass: 'purchase-success-modal',
    componentProps: {
      postAction: product.value.post_action,
      productCode: product.value.code,
      firstPurchaseOffer: product.value.first_purchase_offer,
    },
  });
  const action = await modal.present();
  return action;
};

const openDescriptionModal = async () => {
  if (!product.value) return;
  const modal = await modalController.create({
    component: SiteHtmlModal,
    cssClass: 'modal-big md modal-default show-modal',
    componentProps: {
      productName: product.value.name,
      htmlContent: product.value.description_html || product.value.description,
    },
  });
  const action = await modal.present();
  return action;
};

const startPurchase = async (event: any) => {
  const productCode = product.value?.code;
  const quantity = product.value?.quantity || 1;
  if (!productCode) return;
  await getUsersCurrency();
  const route = useRoute();
  const router = useRouter();
  const currentRouteName = String(route.name);
  const openedFrom = `${currentRouteName}-buy-${productCode}-${quantity}`;
  const modalDismissRes = (await openBuyWithGemsModal(event, product.value)) as any;
  if (!modalDismissRes?.data || typeof modalDismissRes?.data !== 'string') {
    return;
  }
  if (modalDismissRes.data === 'needgems') {
    return openGemShopModal(event);
  }
  if (modalDismissRes.data === COMPLIMENT_BOMB) {
    // thing to do in the background while the post-purchase modal is open
    const action = (await openPurchaseSuccessModal()) as any;
    if (action == 'inventory') {
      return router.push({ name: 'home', query: { modal: 'compliment-bomb-hub' } });
    }
  } else if (modalDismissRes.data && product.value?.post_action) {
    (await openPurchaseSuccessModal()) as any;
  }
  emits('onPurchase', productCode);
};
</script>

<style lang="sass" scoped>
.tag-top-left
  top: 7px
  left: 7px
  min-width: 50px
  background: rgba(0, 0, 0, 0)
  color: #FF2D5A
  z-index: 1
.tag-top-right
  top: 7px
  right: 7px
  color: white
  z-index: 1
  .tag
    background: #FF2D5A
.rest
  font-weight: bold
  width: 100%
.tag
  padding-left: 10px
  padding-right: 10px
  height: 20px
  border: 2px solid #FF2D5A
  font-size: 14px
  font-weight: bold
  border-radius: 12px
img
  height: 100px !important
  object-fit: contain
  z-index: 1
.product-listing
  position: relative
  display: flex
  overflow: hidden
  justify-content: center
  width: 100%
  min-height: 120px
  border-radius: 12px
  border: 3px solid rgb(245, 235, 255)
  box-shadow: 0px 4px 0px 0px rgba(141, 112, 208)
  overflow: hidden
  background-color: rgba(234, 219, 255, 1)
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(to top, rgba(255, 164, 240, 0) 0%, rgba(255, 200, 246, 0.5) 100%)
    z-index: 0
  .image-area
    height: 150px
    width: 150px
    position: relative
    @media (max-width: 419px)
      height: 22vw
      width: 22vw
      min-width: 22vw
      align-self: center
      margin: 0 0.1rem 0 0.45rem
.product-image
  height: 100%
  max-width: 100%
  aspect-ratio: 1
  position: relative
  display: flex
  justify-content: center
  align-items: center
  img
    margin: auto 0
    border-radius: 16px
    min-height: 120px
    max-height: 120px
    max-width: 120px
    @media (max-width: 419px)
      min-height: 22vw
      max-height: 22vw
      max-width: 22vw
.product-image::before, .product-image::after
  content: ""
  position: absolute
  border: none
  border-radius: 16px
.price-button
  --background: linear-gradient(90deg, rgb(177,77,211) 20%, #4155C9 100%)
  --border-radius: 12px
  width: 7rem
  height: 2.8rem
  @media (max-width: 419px)
    width: 5rem
    height: 2.1rem
.price-text
  font-size: 18px
  font-weight: bold
  color: white
  user-select: none !important
  @media (max-width: 419px)
    font-size: 14px
.product-name
  color: #431C89
  display: flex
  font-size: 18px
  font-weight: bold
  user-select: none !important
  margin-bottom: 0.25rem
  @media (max-width: 419px)
    font-size: 16px
    line-height: 16px
.product-description
  color: #431C89
  font-size: 13px
  line-height: 14px
  margin-top: 0
  @media (max-width: 419px)
    font-size: 10px
    line-height: 11px
  @media (max-width: 576px)
    font-size: 12px
    line-height: 13px
.center-area
  min-height: 100%
  flex: 1
  flex-grow: 1
  z-index: 1
  @media (max-width: 576px)
    align-self: center
.button-area
  display: flex-column
  align-self: center
  justify-self: center
  margin: 0 0.75rem 0 0.5rem !important
  @media (max-width: 576px)
    margin: 0 0.25rem !important
.button-inner
  pointer-events: none
  background-color: rgba(255, 255, 255, 0.2)
  position: absolute
  width: 4rem
  height: 14px
  top: -2px
  border-radius: 12px
  z-index: -1
  margin: 0
  padding: 0
  @media (max-width: 419px)
    width: 2.8rem
    height: 8px
    top: -6px
    margin: 0
    padding: 0
.icon
  z-index: 2
  font-size: 1.5rem
  color: #431C89
  cursor: pointer
  @media (max-width: 576px)
    font-size: 1.25rem
    margin-top: 0.25rem
.svg
  width: 1.25rem
  height: 1.25rem
  margin-right: 0.25rem
  @media (max-width: 576px)
    width: 1rem
    height: 1rem
    margin-right: 0.15rem
</style>
