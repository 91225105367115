<template>
  <div class="compact no-shadow tabs-segment bg-transparent d-flex">
    <scroller
      ref="scrollerRef"
      class="scroller"
      :scroll-size="300"
      :threshold="20"
      :no-gradient-background="true"
      :with-background="true"
    >
      <div class="d-flex">
        <ion-segment-button
          v-for="(tab, index) of tabs"
          :key="`${tab.value}-${index}`"
          class="shrink tab"
          :class="pressed === tab.value ? 'text-primary selected-tab' : ''"
          :value="tab.value"
          @click="action(tab.value, index)"
        >
          <ion-label>{{ truncateText(tab.name) }}</ion-label>
        </ion-segment-button>
      </div>
    </scroller>
  </div>
</template>

<script lang="ts" setup>
import { Tab } from '@/types/static-types';

const props = defineProps({
  tabs: {
    type: Array<Tab>,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  pressed: {
    type: String,
    default: 'featured',
  },
});

const tabs = toRef(props, 'tabs');
const pressed = toRef(props, 'pressed');
const scrollerRef: Ref = ref(null);
const emit = defineEmits(['update:activeIndex', 'tab-changed']);
watch(
  tabs,
  () => {
    setTimeout(() => {
      if (scrollerRef.value) (scrollerRef.value as any).calculateScroll();
    }, 150);
  },
  { deep: true }
);

const action = (tag: any, index: any) => {
  pressed.value = tag;
  const tab = props.tabs[index];

  emit('update:activeIndex', index);
  emit('tab-changed', tab);
};
const truncateText = (value: string, length = 15) => {
  return truncate(value, { length, separator: /,? +/ });
};
</script>

<style scoped lang="sass">
.ion-segment
  margin-inline: 10px
.selected-tab
  border-bottom: 3px solid
.shrink
  flex: none !important
.dark .tab
  color: white
.tab
  font-size: 0.8rem
  text-transform: none
  letter-spacing: 0
  font-weight: bold
  height: 43px
  min-height: 43px
  max-height: 43px
  color:#214163
  z-index: 0 !important
.scroller
  pointer-events: all
  ::v-deep .right-scroller
    z-index: 0 !important
    width: 45px !important

  ::v-deep .left-scroller
    z-index: 0 !important
    width: 45px !important
  ::v-deep .scroll-content
    border-bottom: 1px solid lightgrey

  ::v-deep .button
    background: grey !important
    border-radius: 8px !important
    margin-right: 0px !important
    .icon-dark
      color: white !important
  ::v-deep .chevron-button
    height: 35px !important
</style>
