<template>
  <div @click="openModal">
    <slot />
    <CurrencyHintModal
      :boxes="claimedBoxes"
      :is-open="isOpenHintCurrModal"
      @close="closeModal"
      @dismiss-modal="closeModal"
    />
  </div>
</template>

<script lang="ts" setup>
import CurrencyHintModal from '@/shared/modals/CurrencyHintModal.vue';
const props = defineProps({
  claimedBoxes: { type: Object, default: () => {} },
});
const isOpenHintCurrModal = ref(false);
const openModal = () => {
  isOpenHintCurrModal.value = true;
};
const closeModal = () => {
  isOpenHintCurrModal.value = false;
};
</script>

<style></style>
