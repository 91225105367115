<template>
  <div class="w-100">
    <div class="w-100" v-if="!isEmpty(products)">
      <div class="w-100" v-for="(item, index) in products">
        <GemProductCard :product="item" :key="index" class="mt-2 grid-item h-100" />
        <DailyClaimBox v-if="index === dailyClaimBoxIndex" class="w-100 mt-0" />
      </div>
    </div>
    <div v-else class="no-data">No offers available</div>
  </div>
</template>

<script lang="ts" setup>
import DailyClaimBox from '@/shared/pages/store/DailyFreebieClaimBox.vue';
import GemProductCard from '@/shared/components/Store/GemProductCard.vue';

const props = defineProps({
  products: { type: Array, default: [] },
  dailyClaimBoxIndex: { type: Number, default: -1 },
});
const products = toRef(props, 'products');
</script>

<style lang="sass" scoped>
// @media(min-width: 769px) and (max-width: 1184px)
//   .grid-item
//     min-height: 150px
</style>
